import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AchievementTypeEnum } from '@app/model/enums/achievement-type.enum';
import { Achievement } from 'src/app/model/achievement';
import { bundleTypeTextMapPlural } from 'library-explorer';

@Component({
  selector: 'app-achievement-modal',
  templateUrl: './achievement-modal.component.html',
  styleUrls: ['./achievement-modal.component.scss']
})
export class AchievementModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public achievement: Achievement) {}

  public progress = 0;
  public AchievementTypeEnum = AchievementTypeEnum;
  public bundleTypeTextMapPlural = bundleTypeTextMapPlural;

  ngOnInit(): void {
    this.progress = this.achievement.achievementType === AchievementTypeEnum.QUANTITIVE
      ? (this.achievement.numberOfCompletedContents / this.achievement.numberOfContents) * 100
      : 0;
  }
}
