import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PdfViewerService } from '@app/services/pdf-viewer.service';
import { SettingsService } from '@app/services/settings.service';
import { WatermarkService } from '@app/services/watermark.service';
import { PdfViewerAppearanceMode} from 'library-explorer';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit, OnDestroy {

  @Input() public allowDownloadFile = false;
  @Input() public src: string;
  @Input() public watermarkEnabled: boolean;
  @Input() public forceFullScreen: boolean;
  @Output() public loaded: EventEmitter<void> = new EventEmitter();
  @Output() public startLoading: EventEmitter<void> = new EventEmitter();
  @Output() public unload: EventEmitter<void> = new EventEmitter();

  @HostBinding('class.simplified-mode')
  get simplifiedModeCssClass(): boolean {
    return this.simplifiedMode;
  }

  @HostBinding('class.hidden')
  get hiddenCssClass(): boolean {
    return !this.showPdf;
  }

  public showPdf = false;
  public simplifiedMode = true;

  private unsubscribe = new Subject<void>();

  constructor(
    private readonly settingsService: SettingsService,
    private readonly watermarkService: WatermarkService,
    private readonly pdfViewerService: PdfViewerService,
    private readonly elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.pdfViewerService.loadedPdfViewer().pipe(
      filter(_ => !!_),
      takeUntil(this.unsubscribe)
    ).subscribe((instance) => {
      const show = instance === this.elementRef.nativeElement;

      if (show) {
        setTimeout(() => {
          this.showPdf = true;
          this.startLoading.emit();
        })
      } else {
        this.showPdf = false;
        this.unload.emit();
      }      
    })

    setTimeout(() => {
      if (!this.pdfViewerService.isPdfViewerLoaded()) {
        this.pdfViewerService.setCurrentPdfViewer(this.elementRef.nativeElement);
      }
    })
    
    this.settingsService.getSettings().subscribe(data => {
      this.simplifiedMode = data?.pdfViewer?.appearanceMode === PdfViewerAppearanceMode.SIMPLIFIED;
    })
  }
  
  public pdfLoaded(): void {
    this.loaded.emit();
  }

  public loadPdf(): void {
    this.pdfViewerService.setCurrentPdfViewer(this.elementRef.nativeElement);
  }

  public pageRendered(event): void {
    const element = event?.source?.div;
    this.setWatermark(element);
  }

  private setWatermark(element: HTMLElement): void {
    if (!this.watermarkEnabled) {
      return;
    }

    const { height, width } = element?.getBoundingClientRect() || {};
    this.watermarkService.appendWatermark(element, width, height, undefined, 9999);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.pdfViewerService.clearLoadedPdfViewer();
  }
}
