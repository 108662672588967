import { Component, Input, OnInit } from '@angular/core';
import { FileExtensionCategory, LibraryItem } from '../../models';
import { LibraryType } from '../../../models';


@Component({
  selector: 'lib-library-item-icon',
  templateUrl: './library-item-icon.component.html',
  styleUrls: ['./library-item-icon.component.scss']
})
export class LibraryItemIconComponent implements OnInit {
  @Input() public extensionType: FileExtensionCategory;
  @Input() public item: LibraryItem;
  @Input() public size = 64;


  ngOnInit(): void {
    if (this.item?.type === LibraryType.FOLDER) {
      this.setFolderExtensionType();
    }
  }
  
  private setFolderExtensionType(): void {
    if (this.extensionType) {
      return;
    }
    
    this.extensionType = FileExtensionCategory.FOLDER;
  }  
}
