


<div class="module-content-panel">
  <ng-container *ngTemplateOutlet="titleHidden ? listTmp : expansionPanelTmp"></ng-container>
</div>

<ng-template #listTmp>
  <div class="contents-list">
    <app-content-card 
      *ngFor="let child of contents"
      [item]="child"
      (click)="navigate(child)">
    </app-content-card>

    <ng-container *ngIf="isLoading">
      <lib-skeleton-loader 
        class="skeleton-card" 
        *ngFor="let item of placeholders">
      </lib-skeleton-loader>
    </ng-container>
  </div>
</ng-template>

<ng-template #expansionPanelTmp>
  <mat-expansion-panel class="content-group" (afterExpand)="loadContents()">
    <mat-expansion-panel-header>
      <div class="panel-header">
        {{ title }}
        <span class="child-content-indicator">
          <ng-container *ngIf="lessonsCount">
            {{ 'COMMON.lessons_count' | translate : { count: lessonsCount } }}
          </ng-container>

          <ng-container *ngIf="lessonsCount && quizzesCount">&</ng-container>

          <ng-container *ngIf="quizzesCount">
            {{ 'COMMON.quizzes_count' | translate : { count: quizzesCount } }}
          </ng-container>
        </span>
      </div>
    </mat-expansion-panel-header>
  
    <ng-container *ngTemplateOutlet="listTmp"></ng-container>
  </mat-expansion-panel>
</ng-template>
