export const storageKeys = {
  CLASS_LAYOUT: 'llab-class-layout',
  LIBRARY_LIST_LAYOUT: 'llab-library-list-layout',
  PLATFORM_LANGUAGE: 'llab-language',
  COOKIE_CONSENT: 'llab-cookie-consent',
  SCORM_SUSPEND_DATA: 'llab-scorm-suspend-data',
  INTERNAL_SSO_ENABLED: 'llab-internal-sso-enabled',
  BRANCH_NAME: 'llab-branch-name',
  LOGGED_IN_USER: 'llab-logged-id-user',
  REFRESH_TOKEN: 'llab-refresh-token',
  OPENED_LESSON_GROUPS: 'llab-opened-lesson-groups',
  USER_SPENT_TIME: 'llab-user-spent-time',
  OFFLINE_USER_DATA: 'llab-offline-user',
  OFFLINE_COURSES: 'llab-offline-courses',
  OFFLINE_CLASSES: 'llab-offline-classes',
  PENDING_REQUESTS: 'llab-pending-requests',
  BIOMETRICS_ENABLED: 'llab-biometrics-enabled',
  BIOMETRICS_DATA: 'llab-biometrics-data',
  BIOMETRICS_DATA_AVAILABLE: 'llab-biometrics-data-available',
  ENCRYPTION_SECRET: 'llab-encryption-secret',
  SLIDE_BUCKETS_TOOLTIP_SEEN: 'llab-slide-buckets-tooltip-seen',
  PREVENT_VIDEO_LOAD: 'llab-prevent-video-load',
  DEVICE_RESOURCE_ID: 'llab-device-resource-id',
  LAST_SEARCH: 'llab-last-search',
  USER_VIDEO_PROGRESS: 'llab-user-video-progress'
};
