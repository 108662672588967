<div class="quiz-warning-dialog">
  <div mat-dialog-content class="quiz-warning-dialog__content">
    {{message | translate}}
  </div>
  <div mat-dialog-actions class="quiz-warning-dialog__actions">
    <app-loading-button 
      *ngIf="okBtnText"
      cssClass="exit-btn" 
      loadingClass="primary-button-dark"
      [disableRipple]="true" 
      [isLoading]="isLoading"
      (clicked)="okBtnHandler()">
      {{okBtnText | translate}}
    </app-loading-button>

    <button *ngIf="cancelBtnText" color="primary" mat-button (click)="cancelBtnHandler()" class="button dialog-action-button button--gray" [mat-dialog-close]="false">
      {{cancelBtnText | translate}}
    </button>
  </div>
</div>
