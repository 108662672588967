import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileModel } from '../../models';

@Component({
  selector: 'lib-material-file',
  templateUrl: './material-file.component.html',
  styleUrls: ['./material-file.component.scss']
})
export class MaterialFileComponent implements OnInit {
  @Input() public item: FileModel;

  @Input() public deleteEnabled = false;
  @Output() public delete: EventEmitter<void> = new EventEmitter();
  @Output() public download: EventEmitter<void> = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  public downloadFile(): void {
    this.download.emit();
  }

  public deleteFile(): void {
    this.delete.emit();
  }

}
