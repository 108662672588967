import Hls, { Level } from 'hls.js';
import { PlyrDriver, PlyrDriverCreateParams, PlyrDriverDestroyParams, PlyrDriverUpdateSourceParams } from 'ngx-plyr';
import Plyr from 'plyr';

export class HlsjsPlyrDriver implements PlyrDriver {

  hls = new Hls({
    xhrSetup: function(xhr, _url) {
      xhr.withCredentials = true;
    },
    startLevel: -1, // Start with the highest quality available
    maxMaxBufferLength: 30, // Prevent excessive buffering
    capLevelToPlayerSize: true, // Adjust based on player size
    abrEwmaFastLive: 3, // Faster adaptation in live streams
    abrEwmaDefaultEstimate: 5000000, // Start estimate (5Mbps)
  });

  private loaded = false;

  constructor(private autoload: boolean, private videoTracks: Plyr.Track[] = []) {}

  create(params: PlyrDriverCreateParams) {
    this.hls.attachMedia(params.videoElement);

    return new Plyr(params.videoElement, params.options);
  }

  updateSource(params: PlyrDriverUpdateSourceParams) {
    this.loadTracks(params.videoElement);
    params.videoElement.poster = params.source.poster || '';
    
    if (this.autoload) {
      this.load(params.source.sources[0].src);
    }
  }

  destroy(params: PlyrDriverDestroyParams) {
    params.plyr.destroy();
    this.hls.detachMedia();
  }

  load(src: string) {
    if (!this.loaded) {
      this.loaded = true;
      this.hls.loadSource(src);
    }
  }

  public setQualityAndRetrieveMaxResolution(src: string, options: Plyr.Options): Promise<Level> {
    this.load(src);
  
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        resolve(null);
      }, 2000);

      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const availableQualities = this.hls.levels.map((l) => l.height);
        availableQualities.unshift(0);
      
        const maxLevel = this.hls.levels.reverse()?.[0];
  
        Object.assign(options, {
          quality: {
            default: -1,
            options: availableQualities,
            forced: true,        
            onChange: (quality: number) => {
              const index = this.hls.levels.findIndex(level => level.height === quality);
        
              if (index === -1) {
                return;
              }
    
              this.hls.currentLevel = index;
            }
          },
          i18n: {
            qualityLabel: {
              0: 'Auto'
            }
          }
        });

        clearTimeout(timeout);
        resolve(maxLevel);
      });
    });
  }

  private loadTracks(videoElement: HTMLVideoElement): void {
    this.videoTracks?.forEach(plyrTrack => {
      const track = document.createElement('track');
      Object.assign(track, {
        ...plyrTrack,
        srclang: plyrTrack.srcLang
      });

      videoElement.appendChild(track);
    });
  }
}