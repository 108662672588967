<ngx-extended-pdf-viewer
  *ngIf="showPdf; else loadPdfTriggerTemplate"
  [src]="src"
  [showDownloadButton]="allowDownloadFile && !forceFullScreen"
  [print]="allowDownloadFile && !forceFullScreen"
  [zoom]="'page-width'"
  [theme]="'dark'"
  [backgroundColor]="simplifiedMode ? 'white' : '#424242'"
  [showToolbar]="!simplifiedMode"
  [showFindButton]="!forceFullScreen"
  [showBookModeButton]="false"
  [showHorizontalScrollButton]="false"
  [showInfiniteScrollButton]="false"
  [showWrappedScrollButton]="false"
  [showOpenFileButton]="false"
  [showTextEditor]="false"
  [showStampEditor]="false"
  [logLevel]="0"
  (pdfLoaded)="pdfLoaded()"
  (pageRendered)="pageRendered($event)">
</ngx-extended-pdf-viewer>

<ng-template #loadPdfTriggerTemplate>
  <div (click)="loadPdf()" class="pdf-viewer__load-placeholder">
    <lib-icon icon="file-pdf" size="60"></lib-icon>
    Click to view PDF document
  </div>
</ng-template>
