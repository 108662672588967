import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseDTO } from '@app/model/courseDTO.model';
import { UserActivityType } from '@app/model/enums/user-activity-type.enum';
import { ModuleDTO } from '@app/model/moduleDTO.model';
import { CourseService } from '@app/services/api/course.service';
import { AuthService } from '@app/services/auth.service';
import { PayableNodeNavigationService } from '@app/services/payable-node-navigation.service';
import { PaymentService } from '@app/services/payment.service';
import { SettingsService } from '@app/services/settings.service';
import { UserActivityService } from '@app/services/user-activity.service';
import { LanguageStorageService, OrientationOption, WebinarDTO, VideoComponent } from 'library-explorer';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit {
  @ViewChild(VideoComponent) public videoPlayer: VideoComponent;
  @Input() public id: string;
  @Input() public dialogMode = false;

  public item: CourseDTO;
  
  public webinars: WebinarDTO[] = [];
  public modules: ModuleDTO[] = [];

  public contentLoading = true;

  public isLoading = true;

  public bannerCollapsed = false;

  public languages: string;
  public audioLanguages: string;

  public cardOrientation = OrientationOption.HORIZONTAL;

  public readonly activityTypes: typeof UserActivityType = UserActivityType;

  public placeholders = new Array(4);

  public paymentEnabled = false;

  public skipModules = false;

  private isAuth = false;
  
  private subscriptions: Subject<any> = new Subject<any>();

  constructor(
    private readonly userActivityService: UserActivityService,
    private readonly paymentService: PaymentService,
    private readonly router: Router,
    private readonly settingsService: SettingsService,
    private readonly authService: AuthService,
    private readonly languageStorageService: LanguageStorageService,
    private readonly payableNodeNavigationService: PayableNodeNavigationService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly courseService: CourseService) { }

  ngOnInit(): void {
    this.id = this.id || this.activatedRoute.snapshot.params.id;

    this.paymentService.getPaymentConfig().pipe(takeUntil(this.subscriptions)).subscribe(res => {
      this.paymentEnabled = !!res.enabled;
    });

    this.settingsService.getSettings().pipe(takeUntil(this.subscriptions)).subscribe(data => {
      this.skipModules = data.system.skip_module;
    });

    this.authService.isAuthorized()
      .pipe(takeUntil(this.subscriptions))
      .subscribe(data => this.isAuth = data);

    this.courseService.getCourseDetailInfo(this.id)
      .pipe(
        takeUntil(this.subscriptions),
        finalize(() => this.isLoading = false)
      )
      .subscribe(data => {
        this.item = data;
        const availableLanguages = this.languageStorageService.getAvailableLanguages();
        const languages = this.item.translation ? this.item.translation.map(code => availableLanguages.find(language => language.code === code)) : [];
        this.languages = languages.map(item => item.name).join(', ');

        const availableSpeechLanguages = this.languageStorageService.getAvailableSpeechLanguages();
        const currentSpeechLanguages = availableSpeechLanguages
          .filter(speechLang => languages.findIndex(lang => lang.code === speechLang.code) !== -1);
    
        this.audioLanguages = currentSpeechLanguages.map(item => item.name).join(', ');
      });
  }

  public logUserActivity(type: UserActivityType): void {
    this.userActivityService.logUserVideoActivity(type, this.item.id, this.item.entityTypeId, this.item.trailer);
  }

  public navigate(): void {
    if (!this.isAuth) {
      this.router.navigate(['/courses'], { queryParams: { classID: this.item.parent?.id  }});
      return;
    }
  
    this.payableNodeNavigationService.navigate(this.item, null, null, true);
  }

  public playTrailer(): void {
    const isPlaying = this.videoPlayer?.plyr?.playing;

    if (isPlaying) {
      this.videoPlayer?.pauseVideo();
      return;
    }
  
    this.videoPlayer?.startVideo();
  }

  public tabChanged(event: MatTabChangeEvent): void {
    switch (event.index) {
      case 1:
        this.loadContent();
        break;
      case 2:
        this.webinars = [];
        this.loadWebinars();
        break;
    }
  }

  private loadContent(): void {
    this.contentLoading = true;

    this.courseService.getCourseModulesList(this.id)
      .pipe(
        finalize(() => this.contentLoading = false),
        takeUntil(this.subscriptions)
      )
      .subscribe(data => this.modules = data.items);
  }

  private loadWebinars(): void {
    this.contentLoading = true;

    this.courseService.getCourseWebinarsList(this.id)
      .pipe(
        finalize(() => this.contentLoading = false),
        takeUntil(this.subscriptions)
      )
      .subscribe(data => this.webinars = data.items)
  }

}
