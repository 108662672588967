import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Achievement } from '@app/model/achievement';
import { AchievementTypeEnum } from '@app/model/enums/achievement-type.enum';
import { AchievementModalComponent } from '../achievement-modal/achievement-modal.component';

@Component({
  selector: 'app-achievement-card',
  templateUrl: './achievement-card.component.html',
  styleUrls: ['./achievement-card.component.scss']
})
export class AchievementCardComponent implements OnInit {

  @Input() achievement: Achievement;

  public AchievementTypeEnum = AchievementTypeEnum;
  public progress = 0;

  constructor(
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.progress = this.achievement.achievementType === AchievementTypeEnum.QUANTITIVE
      ? (this.achievement.numberOfCompletedContents / this.achievement.numberOfContents) * 100
      : 0;
  }

  public openModal() {
    this.dialog.open(AchievementModalComponent, {
      disableClose: false,
      panelClass: ['mobile-full-width-dialog', 'dialog-no-padding', 'dialog-no-margin'],
      width: '500px',
      closeOnNavigation: true,
      data: {...this.achievement}
    });
  }

}
