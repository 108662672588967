<div *ngIf="achievement" class="achievement-card" [class.not-achieved]="!achievement.achieved && !progress" (click)="openModal()">
  <div *ngIf="achievement.achieved || (!achievement.achieved && !progress)" class="achievement-card__icon">
    <lib-icon [icon]="achievement.achieved ? 'check' : 'lock-simple'" size="16"></lib-icon>
  </div>
  <div class="achievement-card__image">
    <img 
      [placeholderImage]="!achievement.mediaImage && achievement.bundle"
      [cropImage]="achievement.mediaImage | preSignedUrl | async" 
      [additionalOptions]="{width: 150, height: 150, func: 'bound'}" 
      alt="achievement">
  </div>
  <div class="achievement-card__title">
    {{achievement.title}}
  </div>
  <div *ngIf="achievement.achievementType === AchievementTypeEnum.QUANTITIVE" class="achievement-card__progress">
    <mat-progress-bar class="default-theme" mode="determinate" [value]="progress"></mat-progress-bar> {{achievement.numberOfCompletedContents}}/{{achievement.numberOfContents}}
  </div>
</div>