import { Component, Input, OnInit } from '@angular/core';
import { FileExtensionCategory } from '../../../library-explorer/models';
import { FileExtensionService } from '../../../library-explorer/services/file-extension/file-extension.service';
import { FileModel } from '../../models';

@Component({
  selector: 'lib-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss']
})
export class FileIconComponent implements OnInit {
  @Input() public file: FileModel;
  @Input() public extensionType: FileExtensionCategory;
  @Input() public size = 64;

  public iconCode: string;

  constructor(private readonly fileExtensionService: FileExtensionService) { }

  ngOnInit(): void {
    if (this.extensionType) {
      this.iconCode = this.fileExtensionService.getFileIconForExtensionType(this.extensionType);
      return;
    }

    this.iconCode = this.fileExtensionService.getFileIcon(this.file);
  }
}