import { Directive, ElementRef, Injector, Input, OnChanges, OnDestroy, Optional, Renderer2, Self, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[autoTextDirection]'
})
export class AutoTextDirectionDirective implements OnChanges, OnDestroy {

  @Input() autoTextDirectionValue: string;
  @Input() autoTextDirection: boolean = true;

  private unsubscribe: Subject<void> = new Subject();

  private readonly RTL_CHAR_REGEXP = /[\u04c7-\u0591\u05D0-\u05EA\u05F0-\u05F4\u0600-\u06FF]/i;

  constructor(
    @Optional()
    @Self()
    public control: NgControl,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private injector: Injector
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    const { autoTextDirection, autoTextDirectionValue } = changes;

    if (this.autoTextDirection === false) {
      this.unsubscribe.next();
      return;
    }

    if (autoTextDirection && !!autoTextDirection?.previousValue !== !!autoTextDirection?.currentValue) {
      this.unsubscribe.next();

      if (autoTextDirection?.currentValue && !this.autoTextDirectionValue) {
        const abstractControl = this.control?.control;

        if (!abstractControl) {
          return;
        }

        abstractControl.valueChanges.pipe(
          takeUntil(this.unsubscribe),
          startWith(abstractControl.value),
          debounceTime(300),
          distinctUntilChanged()
        ).subscribe(this.processTextValue.bind(this));
      }
    }

    if (autoTextDirectionValue?.currentValue && (autoTextDirectionValue?.previousValue !== autoTextDirectionValue?.currentValue)) {
      this.processTextValue(autoTextDirectionValue.currentValue);
    }
  }

  private processTextValue(value: string) {
    const isRtl = this.RTL_CHAR_REGEXP.test(value);
    const direction = isRtl ? 'rtl' : 'ltr';
    this.renderer.setAttribute(this.elementRef.nativeElement, 'dir', direction);
  }
  
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
