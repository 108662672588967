import { Injectable } from '@angular/core';
import { NgxExtendedPdfViewerComponent, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfViewerService {

  private _loadedPdfViewer: BehaviorSubject<NgxExtendedPdfViewerComponent> = new BehaviorSubject(null);

  constructor() { }

  public setCurrentPdfViewer(instance: NgxExtendedPdfViewerComponent): void {
    this._loadedPdfViewer.next(instance);
  }

  public isPdfViewerLoaded(): boolean {
    return this._loadedPdfViewer.getValue() !== null;
  }

  public loadedPdfViewer(): Observable<NgxExtendedPdfViewerComponent> {
    return this._loadedPdfViewer.asObservable();
  }

  public clearLoadedPdfViewer(): void {
    this._loadedPdfViewer.next(null);
  }
}
