import { CommonModule as AngularCommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { PlyrModule } from 'ngx-plyr';
import { ChipsComponent, FileIconComponent, MaterialFileComponent, SkeletonLoaderComponent, SvgIconComponent, UserAvatarComponent, VideoComponent, VimeoPlayerComponent, YoutubePlayerComponent } from './components';
import { AudioWaveformPlayerComponent } from './components/audio-waveform-player/audio-waveform-player.component';
import { IconComponent } from './components/icon/icon.component';
import { ListLayoutSwitcherComponent } from './components/list-layout-switcher/list-layout-switcher.component';
import { bundleTypeTextMap, dateFormats, PageBlockKeysMap } from './constants';
import { API_URL, BUNDLE_TYPE_TEXT_MAP, DATE_FORMATS, REGEX_LIST, SETTINGS_SERVICE, STORAGE_KEYS, PAGE_BLOCK_KEYS_MAP, API_CORE_APP_URL, OVERRIDE_ICONS_FROM_SETTINGS } from './constants/injection-tokens';
import { regexList } from './constants/regex-list.const';
import { storageKeys } from './constants/storage-keys';
import { AutoTextDirectionDirective, BlockBackgroundDirective, CardBackgroundDirective, CropImageDirective, MediaBackgroundDirective, NativeAppConditionalVisibilityDirective, PaddingDirective, PlaceholderImageDirective, SlideBackgroundDirective, TextImageMaskDirective } from './directives';
import { RtlDirective } from './directives/rtl.directive';
import { BrowserStorage } from './models/browser-storage';
import { CropImagePipe, MinutesToTimePipe, PreSignedUrlPipe, SafeHtmlPipe, SanitizeHtmlPipe, SecondsToTimePipe, SettingsMediaPresignedUrl, VideoMediaPresignPipe } from './pipes';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { BaseSettingsGetService, DefaultCookieConsentProvider, LocalStorageService, OneTrustCookieConsentProvider } from './services';
import { ScriptLoaderService } from './services/script-loader.service';
import { SectionMinHeightDirective } from './directives/section-min-height.directive';
import { EllipsisModule } from 'ngx-ellipsis';

const COMPONENTS = [
  VideoComponent,
  VimeoPlayerComponent,
  YoutubePlayerComponent,
  IconComponent,
  ListLayoutSwitcherComponent,
  SkeletonLoaderComponent,
  ChipsComponent,
  AudioWaveformPlayerComponent,
  SvgIconComponent,
  UserAvatarComponent,
  FileIconComponent,
  MaterialFileComponent
];

const DIRECTIVES = [
  CropImageDirective,
  SlideBackgroundDirective,
  CardBackgroundDirective,
  MediaBackgroundDirective,
  BlockBackgroundDirective,
  AutoTextDirectionDirective,
  TextImageMaskDirective,
  PaddingDirective,
  NativeAppConditionalVisibilityDirective,
  PlaceholderImageDirective,
  SectionMinHeightDirective
];

const PIPES = [
  SafeHtmlPipe,
  PreSignedUrlPipe,
  CustomDatePipe,
  CropImagePipe,
  SecondsToTimePipe,
  RtlDirective,
  VideoMediaPresignPipe,
  SanitizeHtmlPipe,
  MinutesToTimePipe,
  SettingsMediaPresignedUrl
]

const MODULES = [
  AngularCommonModule,
  MatIconModule,
  MatButtonModule,
  MatProgressSpinnerModule,
  PlyrModule,
  TranslateModule,
  EllipsisModule
]

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    FileIconComponent
  ],
  imports: [
    ...MODULES
  ],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    ...MODULES
  ],
  providers: [
    DefaultCookieConsentProvider,
    OneTrustCookieConsentProvider,
    SettingsMediaPresignedUrl
  ]
})
export class SharedLibraryModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, scriptLoader: ScriptLoaderService) {
    scriptLoader.loadScript('./library-explorer/assets/js/phosphor-icons/index.js', 'phosphor-icons');

    const icons = [
      'edit', 'card-portrait', 'card-landscape', 'bin', 'microphone', 'handle'
    ];

    icons.forEach(key => {
      matIconRegistry.addSvgIcon(
        `lms-${key}`,
        domSanitizer.bypassSecurityTrustResourceUrl(`/library-explorer/assets/icons/${key}.svg`)
      );
    });
  }

  public static forRoot(config: { apiUrl: string, apiCoreAppUrl: string, iconOverrideFromSettings?: boolean, settingsClassProvider: Type<BaseSettingsGetService<any>>}): ModuleWithProviders<SharedLibraryModule> {
  
    return {
      ngModule: SharedLibraryModule,
      providers: [
        {
          provide: SETTINGS_SERVICE,
          useExisting: config.settingsClassProvider
        },
        {
          provide: REGEX_LIST,
          useValue: regexList
        },
        {
          provide: STORAGE_KEYS,
          useValue: storageKeys
        },
        {
          provide: BrowserStorage,
          useClass: LocalStorageService
        },
        {
          provide: API_URL,
          useValue: config.apiUrl
        },
        {
          provide: API_CORE_APP_URL,
          useValue: config.apiCoreAppUrl
        },
        {
          provide: OVERRIDE_ICONS_FROM_SETTINGS,
          useValue: config.iconOverrideFromSettings
        },
        {
          provide: DATE_FORMATS,
          useValue: dateFormats
        },
        {
          provide: BUNDLE_TYPE_TEXT_MAP,
          useValue: bundleTypeTextMap
        },
        {
          provide: PAGE_BLOCK_KEYS_MAP,
          useValue: PageBlockKeysMap
        }
      ]
    };
  }
}
