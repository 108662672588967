import { Injectable } from '@angular/core';
import { FileModel, ImageModel } from '../../../common/models';

import { LibraryType } from '../../../models';
import { FileExtensionCategory, LibraryItem } from '../../models';


@Injectable({
  providedIn: 'root'
})
export class FileExtensionService {

  private readonly extensionIconsMap = {
    'file-image': [
      'jpg', 'jpeg', 'bmp', 'png', 'gij'
    ],
    'file-pdf': ['pdf'],
    'file-xls': ['xls', 'xlsx'],
    'file-zip': ['zip', 'rar', 'tar', '7z'],
    'file-ppt': ['ppt', 'pptx'],
    'file-doc': ['dot', 'dotx', 'doc', 'docx'],
    'file-video': ['mp4', 'mov', 'wmv', 'mkv', 'webm'],
    'file-audio': ['mp3', 'wav']
  };

  private readonly extensionTypeIconMap = {
    'file-image': FileExtensionCategory.IMAGE,
    'file-pdf': FileExtensionCategory.PDF,
    'file-xls': FileExtensionCategory.SPREADSHEET,
    'file-doc': FileExtensionCategory.DOCUMENT,
    'file-video': FileExtensionCategory.VIDEO,
    'file-audio': FileExtensionCategory.AUDIO,
    'file-zip': FileExtensionCategory.ARCHIVE,
    'folder': FileExtensionCategory.FOLDER
  };

  constructor() { }

  public getLibraryItemPreviewImage(item: LibraryItem): ImageModel {
    if (!item) {
      return null;
    }

    if (item.mediaImage) {
      return item.mediaImage;
    }
  
    if (item.type === LibraryType.FOLDER) {
      return null;
    }

    const extension = item.file?.extension?.toLowerCase();

    if (!extension) {
      return null;
    }

    const isImage = this.extensionIconsMap['file-image'].indexOf(extension) !== -1;

    if (isImage) {
      return item.file;
    }

    return null;
  }

  public getFileIcon(file: FileModel): string {
    let extension = file?.extension?.toLowerCase();

    if (!extension) {
      const name = file?.filename || file?.name || '';
      const parts = name.split('.');
      extension = parts.length > 1 ? parts.pop()?.toLowerCase() : null;
    }

    if (!extension) {
      return 'file';
    }

    const [iconKey] = Object.entries(this.extensionIconsMap).find(([_, values]) => values.indexOf(extension) !== -1);

    return iconKey || 'file'
  }

  public getFileIconByName(name: string): string {
    const parts = name ? name.split('.') : [];
    const extension = parts.length > 1 ? parts.pop()?.toLowerCase() : null;

    if (!extension) {
      return 'file';
    }

    const [iconKey] = Object.entries(this.extensionIconsMap).find(([_, values]) => values.indexOf(extension) !== -1);

    return iconKey || 'file';
  }


  public getFileIconForExtensionType(type: FileExtensionCategory): string {
    if (!type) {
      return 'file';
    }

    const [iconKey] = Object.entries(this.extensionTypeIconMap).find(([_, value]) => value === type);

    return iconKey || 'file';
  }
  
}
