import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SETTINGS_SERVICE } from '../constants';
import { AccessTokenType, SettingsModel } from '../models';
import { BaseSettingsGetService } from './base-settings.service';
import { SharedLibraryService } from './shared-library.service';
import { TokenApiService } from './token-api.service';

@Injectable({
  providedIn: 'root'
})
export class CloudImageService {
  private readonly skipForceFormatExtensions = ['gif'];
  private readonly defaultImageQuality = 90;

  private readonly mobileMediaQuery = window.matchMedia('(max-width: 768px)');

  constructor(
    @Inject(SETTINGS_SERVICE) private readonly settingsService: BaseSettingsGetService,
    private readonly tokenApiService: TokenApiService) { }

  public generateCloudImageResourceUrl(resourceUrl: string, width: number = null, additionalOptions = {}, crop = '', isPrivate = false, id = null): Observable<string> {
    if (!resourceUrl) {
      return of('');
    }
  
    let imageOptions: { [key: string]: string } = {
      force_format: this.getForceFormatParam(resourceUrl),
      org_if_sml: '1',
      width: width ? Math.floor(width).toString() : undefined,
      ...additionalOptions,
    };

    const isMobile = this.mobileMediaQuery?.matches;

    if (isMobile) {
      const dpr = Math.min(window.devicePixelRatio || 1, 5);

      Object.assign(imageOptions, { dpr });
    }

    if (crop) {
      const [x1, y1, x2, y2] = crop.split(',');
  
      Object.assign(imageOptions, {
        tl_px: `${x1},${y1}`,
        br_px: `${x2},${y2}`
      });
    }

    imageOptions = this.removeNullOptions(imageOptions);

    if (!crop && !additionalOptions) {
      return of(resourceUrl);
    }

    const accessRequest = isPrivate ? this.tokenApiService.getTokenForFileAccess(AccessTokenType.FILE, id) : of(null);

    return accessRequest
      .pipe(
        mergeMap(token => {
          if (token) {
            Object.assign(imageOptions, { token });
          }

          return this.settingsService.getSettings();
        }),
        map(settings => { 
          Object.assign(imageOptions, { q: this.getImageQuality(settings) });

          const options = Object.keys(imageOptions).map(key => {
            if (!imageOptions[key]) {
              return '';
            }
          
            return `${key}=${imageOptions[key]}`;
          }).filter(item => !!item);
      
          const imageUrlHasQueryParams = resourceUrl.indexOf('?') !== -1;
          const joinSeparator = imageUrlHasQueryParams ? '&' : '?';

          return `${resourceUrl}${joinSeparator}${options.join('&')}`;
        })
      );
  }

  private getForceFormatParam(url: string): string {
    const extension = url?.split(/[#?]/)[0].split('.').pop()?.trim() || '';
    const forceFormatParam = this.skipForceFormatExtensions.indexOf(extension) === -1;

    if (!forceFormatParam) {
      return undefined;
    }

    return 'jpeg';
  }

  private getImageQuality(settings: SettingsModel): number {
    const quality = settings?.layout?.imageQuality;

    if (!quality || quality > 100 ||  quality < 50) {
      return this.defaultImageQuality;
    }

    return quality - 10;
  }

  private removeNullOptions(imageOptions: any): any {
    if (!imageOptions) {
      return null;
    }

    for (const propName in imageOptions) {
      if (imageOptions[propName] === null || imageOptions[propName] === undefined) {
        delete imageOptions[propName];
      }
    }

    return imageOptions;
  }
}
