import { LocationStrategy, PathLocationStrategy, ViewportScroller } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageMap, StorageModule } from '@ngx-pwa/local-storage';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GtagModule } from 'angular-gtag';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HotkeyModule } from 'angular2-hotkeys';
import { CookieService } from 'ngx-cookie-service';
import { DisqusModule } from 'ngx-disqus';
import { NgxMaskModule } from 'ngx-mask';
import { NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CertificateModule } from './components/certificate/certificate.module';
import { FooterModule } from './components/commons/footer/footer.module';
import { MaintenanceModule } from './components/maintenance/maintenance.module';
import { ToastComponent } from './components/toast/toast.component';
import { TranslationKeyToggleComponent } from './components/translation-key-toggle/translation-key-toggle.component';
import { GlobalErrorHandler } from './handlers/global-error.handler';
import { ClientIdentificationInterceptor } from './interceptors/client-identification.interceptor';
import { HttpErrorHandlingInterceptor } from './interceptors/http-error-handling.interceptor';
import { CustomTranslationLoaderService } from './services/custom-translation-loader.service';

import { InitializeService } from './services/initialize.service';
import { LanguageService } from './services/language.service';
import { ChatSharedModule } from './shared/components/chat-shared/chat-shared.module';
import { SharedModule } from './shared/shared.module';
import { ContentVisibilityInterceptor } from './interceptors/content-visibility.interceptor';
import {
  LocalStorageService,
  MaintenanceInterceptor,
  storageKeys,
  BrowserStorage,
  STORAGE_KEYS,
  SharedLibraryModule,
  SharedTranslateCompilerService,
  PreSignedUrlInterceptor,
  CookieCategory
} from 'library-explorer';
import { ApplicationVersionInterceptor } from './interceptors/application-version.interceptor';
import { NgxMatomoTrackerModule, MatomoInitializationMode } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatomoCustomRouteInterceptor } from './interceptors/matomo-custom-route.interceptor';
import { BranchIdentificationInterceptor } from './interceptors/branch-identification.interceptor';
import { AcceptLanguageInterceptor } from './interceptors/accept-language.interceptor';
import { OfflineRequestInterceptor } from './interceptors/offline-request.interceptor';
import { environment } from '@env/environment';
import { Event, Router, Scroll } from '@angular/router';
import { SettingsService } from './services/settings.service';
import { SkipTokenInterceptor } from './interceptors/skip-token.interceptor';
import { LoadingTokenInterceptor } from './interceptors/loading-token.interceptor';
import { LoadingToastComponent } from './components/loading-toast/loading-toast.component';
import { ScrollPositionInterceptor } from './interceptors/scroll-position.interceptor';
import { filter, pairwise } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { NavigateService } from './services/navigate.service';
import { OneTrustModule } from '@altack/ngx-onetrust';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

export function init_app(initializeService: InitializeService, injector: Injector) {
  return () => initializeService.init(injector);
}

const disableAnimations = !('animate' in document.documentElement)
  || (navigator && /iPhone OS (8|9|10|11|12|13)_/.test(navigator.userAgent));

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    TranslationKeyToggleComponent,
    LoadingToastComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    SharedModule,
    ChatSharedModule,
    HttpClientModule,
    DisqusModule.forRoot('thelearning-lab'),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      toastComponent: ToastComponent,
    }),
    ServiceWorkerModule.register('custom-ngsw-worker.js', { enabled: true, registrationStrategy: 'registerImmediately' }),
    StorageModule.forRoot({ IDBNoWrap: true }),
    GtagModule.forRoot({ trackingId: '', trackPageviews: true }),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslationLoaderService,
        deps: [HttpClient, Injector, StorageMap]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: SharedTranslateCompilerService
      }
    }),
    NgxMatomoTrackerModule.forRoot({
      mode: MatomoInitializationMode.AUTO_DEFERRED,
    }),
    NgxMatomoRouterModule.forRoot({
      interceptors: [MatomoCustomRouteInterceptor],
    }),
    FooterModule,
    NgxMaskModule.forRoot(),
    CertificateModule,
    HotkeyModule.forRoot(),
    MaintenanceModule,
    SharedLibraryModule.forRoot({ 
      apiUrl: environment.apiUrl, 
      apiCoreAppUrl: environment.apiCoreAppUrl,
      iconOverrideFromSettings: true, 
      settingsClassProvider: SettingsService 
    }),
    OneTrustModule.forRoot({
      cookiesGroups: {
        StrictlyNecessaryCookies: CookieCategory.NECCESSARY,
        PerformanceCookies: CookieCategory.PERFORMANCE,
        FunctionalCookies: CookieCategory.FUNCTIONAL,
        TargetingCookies: CookieCategory.TARGETING,
        SocialMediaCookies: CookieCategory.SOCIAL_MEDIA
      }
    }),
    NgxExtendedPdfViewerModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    CookieService,
    InitializeService,
    LanguageService,
    {
      provide: STORAGE_KEYS,
      useValue: storageKeys
    },
    {
      provide: BrowserStorage,
      useClass: LocalStorageService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [InitializeService, Injector],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorHandlingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientIdentificationInterceptor,
      multi: true
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: BranchIdentificationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentVisibilityInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplicationVersionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SkipTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScrollPositionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PreSignedUrlInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        scrollAuditTime: 20
      }
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {

  constructor(router: Router, private viewportScroller: ViewportScroller, private navigateService: NavigateService) {
    router.events.pipe(
      filter((event: Event) => event instanceof Scroll),
      pairwise()
    ).subscribe(([previousEvent, event]: [Scroll, Scroll]) => {
      const scrollElement = document.getElementById('full-layout-scroll-section');

      // Capture the current URL after redirection
      this.navigateService.currentPageUrl = event.routerEvent.urlAfterRedirects;

      // Update previous page URL and scroll position
      if (Capacitor.isNativePlatform() && this.navigateService.prevPageUrl !== this.navigateService.currentPageUrl) {
        this.navigateService.prevPageUrl = previousEvent.routerEvent.urlAfterRedirects;
        this.navigateService.prevPageScrollPos = scrollElement
          ? [scrollElement.scrollLeft, scrollElement.scrollTop]
          : this.viewportScroller.getScrollPosition();
      }

      if (event.position) {
        scrollElement
          ? scrollElement.scrollTo({ left: event.position[0], top: event.position[1] })
          : this.viewportScroller.scrollToPosition(event.position);
        return;
      }

      // Handle anchor navigation
      if (event.anchor) {
        this.viewportScroller.scrollToAnchor(event.anchor);
        return;
      }

      // Reset scroll position if navigating to a new page
      const prevUrl = previousEvent?.routerEvent?.urlAfterRedirects?.split('?')[0];
      const currentUrl = event?.routerEvent?.urlAfterRedirects?.split('?')[0];

      if (prevUrl !== currentUrl) {
        scrollElement
          ? scrollElement.scrollTo({ left: 0, top: 0 })
          : this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

}
