<div class="course-details" [class.dialog-mode]="dialogMode">
  <ng-scrollbar thumbClass="custom-scrollbars" [autoHeightDisabled]="false" visibility="hover">
    <div class="course-banner">
      <div class="course-media">
        <div class="image-placeholder" [placeholderImage]="!item?.mediaImage && item?.bundle"></div>

        <ng-container *ngIf="isLoading && !item">
          <lib-skeleton-loader class="image-loading-placeholder" width="100%" height="100%"></lib-skeleton-loader>
        </ng-container>

        <ng-container *ngIf="item?.trailer; else bannerImageTmp">
          <div class="course-video" (click)="playTrailer()" [class.video-playing]="videoPlayer?.plyr?.playing">
            <button mat-flat-button class="button button--gray video-play-button">
              <lib-icon [icon]="videoPlayer?.plyr?.playing ? 'pause' : 'play'"></lib-icon>

              <span class="video-play-button-text">
                {{ 'COURSE_DETAILS.watch_trailer' | translate }}
              </span>
            </button>

            <lib-video class="course-video-player" [src]="item.trailer | videoPresign | async" [hideControls]="true" type="video/mp4"
              [video]="item.trailer" (started)="logUserActivity(activityTypes.USER_START_WATCHING_VIDEO)"
              (watched)="logUserActivity(activityTypes.USER_WATCHED_VIDEO)"
              [poster]="{ uri: item.mediaImage | preSignedUrl | async | imageCrop : item.mediaImage?.crop | async }">
            </lib-video>
          </div>
        </ng-container>

        <ng-template #bannerImageTmp>
          <div class="course-image" [cropImage]="item?.mediaImage | preSignedUrl | async" [additionalOptions]="{ aspect_ratio: '16:9' }"
            [crop]="item?.mediaImage?.crop"></div>
        </ng-template>
      </div>

      <ng-container *ngIf="!dialogMode">
        <ng-container *ngTemplateOutlet="baseInformationTmp"></ng-container>
      </ng-container>

    </div>

    <div class="course-details-body">
      <ng-container *ngIf="dialogMode">
        <ng-container *ngTemplateOutlet="baseInformationTmp"></ng-container>
      </ng-container>

      <mat-tab-group 
        class="courses-tabs" 
        [disableRipple]="true" 
        animationDuration="200"
        (selectedTabChange)="tabChanged($event)">

        <mat-tab [disabled]="!item">
          <ng-template mat-tab-label>
            <label class="course-tab-label">
              {{ 'COURSE_DETAILS.details' | translate }}
            </label>
          </ng-template>

          <ng-template matTabContent>
            <div class="course-tab-content course-about-tab" *ngIf="item">

              <div class="course-main-info">
                <div class="course-info-section">
                  <h3 class="tab-content-title">
                    {{ 'COURSE_DETAILS.about_course' | translate }}
                  </h3>

                  <div [innerHTML]="item.description | safeHtml"></div>

                  <span class="course-learning-path" *ngIf="item.relatedLearningPaths?.[0]">
                    {{ 'COURSE_DETAILS.part_of' | translate }}
                    <lib-icon class="learning-path-icon" icon="path" size="16"></lib-icon>
                    <strong class="learning-path-title">{{ item.relatedLearningPaths?.[0]?.title }}</strong>
                  </span>
                </div>

                <div class="course-info-section" *ngIf="item.achievementEnabled || item.certificationEnabled || item.leaderboardPoints">
                  <h4 class="tab-content-title tab-content-title--sub">
                    {{ 'COMMON.achievements' | translate }}
                  </h4>

                  <div class="course-achievements">
                    <div class="course-achievement-item" *ngIf="item.leaderboardPoints">
                      <div class="achievement-icon achievement-icon--purple">
                        <lib-icon size="36" icon="currency-eth"></lib-icon>
                      </div>

                      <div class="achievement-info">
                        <h6 class="achievement-title">+ {{item.leaderboardPoints}}</h6>
                        <span>{{'COMMON.experience_points' | translate}}</span>
                      </div>
                    </div>

                    <div class="course-achievement-item" *ngIf="item.achievementEnabled">
                      <div class="achievement-icon achievement-icon--purple">
                        <lib-icon size="36" icon="medal"></lib-icon>
                      </div>

                      <div class="achievement-info">
                        <h6 class="achievement-title">{{ item?.achievements?.[0]?.title }}</h6>
                        <span>{{'COMMON.badge' | translate}}</span>
                      </div>
                    </div>

                    <div class="course-achievement-item" *ngIf="item.certificationEnabled">
                      <div class="achievement-icon achievement-icon--green">
                        <lib-icon size="36" icon="certificate"></lib-icon>
                      </div>

                      <div class="achievement-info">
                        <h6 class="achievement-title">{{ item?.certifications?.[0]?.title }}</h6>
                        <span>{{'COMMON.certificate' | translate}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="course-stats">
                <div class="course-stat" *ngIf="item.time">
                  <lib-icon icon="clock"></lib-icon>
                  {{ item.time | minutesToTime : 'short' }}
                </div>

                <div class="course-stat">
                  <lib-icon icon="book-open-text"></lib-icon>
                  {{ 'COMMON.lessons_count' | translate : { count: item.lessonsCount } }}
                </div>

                <div class="course-stat">
                  <lib-icon icon="game-controller"></lib-icon>
                  {{ 'COMMON.quizzes_count' | translate : { count: item.quizzesCount } }}
                </div>

                <div class="course-stat" *ngIf="item.filesCount">
                  <lib-icon icon="stack"></lib-icon>

                  {{ 'COMMON.additional_materials' | translate : { count: item.filesCount } }}
                </div>

                <div class="course-stat" *ngIf="audioLanguages?.length">
                  <lib-icon icon="headphones"></lib-icon>
                  {{'COMMON.audio' | translate }}: {{ audioLanguages }}
                </div>

                <div class="course-stat" *ngIf="languages?.length">
                  <lib-icon icon="globe"></lib-icon>
                  {{'COMMON.languages' | translate }}: {{ languages }}
                </div>

                <div class="course-stat" *ngIf="item.certificationEnabled">
                  <lib-icon icon="certificate"></lib-icon>
                  {{'COMMON.certificate' | translate}}
                </div>

                <div class="course-stat" *ngIf="item.leaderboardPoints">
                  <lib-icon icon="currency-eth"></lib-icon>

                  {{ 'LEADERBOARD.points_count' | translate : { count: item.leaderboardPoints } }}
                </div>

              </div>
            </div>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="item?.lessonsCount + item?.quizzesCount">
          <ng-template mat-tab-label>
            <label class="course-tab-label">
              {{ 'COMMON.lesson_and_quizzes' | translate }}
              <span class="label-counter">
                {{ item?.lessonsCount + item?.quizzesCount }}
              </span>
            </label>
          </ng-template>

          <ng-template matTabContent>
            <div class="course-tab-content list-content-tab" *ngIf="item">
              <h3 class="tab-content-title">
                {{ 'COMMON.lesson_and_quizzes' | translate }}
              </h3>

              <app-module-content-expansion-panel 
                *ngFor="let child of modules; let first = first" 
                [id]="child.id" 
                [title]="child.title"
                [titleHidden]="skipModules || modules?.length === 1" 
                [quizzesCount]="child.quizzesCount" 
                [lessonsCount]="child.lessonsCount"
                [autoOpen]="first">
              </app-module-content-expansion-panel>

              <ng-container *ngIf="contentLoading">
                <ng-container *ngTemplateOutlet="cardsPlaceholder"></ng-container>
              </ng-container>


            </div>
          </ng-template>
        </mat-tab>

        <mat-tab *ngIf="item && item?.webinarsCount">
          <ng-template mat-tab-label>
            <label class="course-tab-label">
              {{ 'COMMON.webinars' | translate }}
              <span class="label-counter">{{item.webinarsCount}}</span>
            </label>
          </ng-template>

          <ng-template matTabContent>
            <div class="course-tab-content list-content-tab" *ngIf="item">
              <h3 class="tab-content-title">
                {{ 'COMMON.webinars' | translate }}
              </h3>

              <app-event-card 
                *ngFor="let item of webinars" 
                [item]="item" 
                [orientation]="cardOrientation">
              </app-event-card>

              <ng-container *ngIf="contentLoading">
                <ng-container *ngTemplateOutlet="cardsPlaceholder"></ng-container>
              </ng-container>

            </div>
          </ng-template>
        </mat-tab>

      </mat-tab-group>
    </div>
  </ng-scrollbar>
</div>

<ng-template #baseInformationTmp>
  <div class="course-base-info">
    <h6 class="bundle-label">{{'COMMON.course' | translate }}</h6>

    <h2 class="course-title">
      <ng-container *ngIf="isLoading && !item?.title">
        <lib-skeleton-loader width="320px" height="48px"></lib-skeleton-loader>
      </ng-container>

      {{item?.title}}
    </h2>

    <div class="course-actions">
      <button mat-flat-button class="button button--primary button--large" (click)="navigate()" [disabled]="!item" cdkFocusInitial>
        <ng-container *ngIf="!item || (!paymentEnabled || !item?.payable || item?.isPaid); else courseNotPaid">
          <lib-icon icon="play"></lib-icon>
          {{ 'COMMON.start_course' | translate }}
        </ng-container>


        <ng-template #courseNotPaid>
          <lib-icon icon="shopping-cart"></lib-icon>
          {{ 'COMMON.buy_course' | translate }}
          {{ item?.price | currency:( item?.currency | uppercase):'symbol' }}
        </ng-template>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #cardsPlaceholder>
  <lib-skeleton-loader class="skeleton-card" *ngFor="let item of placeholders"></lib-skeleton-loader>
</ng-template>