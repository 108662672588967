import { registerPlugin } from '@capacitor/core';

export interface ScrollViewPlugin {
  enableContentInset(): Promise<{ status: boolean }>;
  disableContentInset(): void;
  contentInsetStatus(): Promise<{ value: string }>;
}

const ScrollView = registerPlugin<ScrollViewPlugin>('ScrollView');

export default ScrollView;
