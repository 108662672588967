import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateRangeFilterOption } from '../../../common/models';
import { FileExtensionCategory, LibraryFilters } from '../../models';

@Component({
  selector: 'lib-library-filters',
  templateUrl: './library-filters.component.html',
  styleUrls: ['./library-filters.component.scss']
})
export class LibraryFiltersComponent implements OnInit, OnDestroy {
  @Input() public cssClass = 'ng-select-primary';
  @Input() public hidePinned = false;
  @Input() public tags: { id: string, title: string }[] = [];
  @Input() public set filters(value: LibraryFilters) {
    this._filters = value;
  
    this.patchFormValue();
  }

  public get filters() {
    return this._filters;
  }

  @Output() changed: EventEmitter<LibraryFilters> = new EventEmitter<LibraryFilters>();

  public form: FormGroup;

  public readonly types: typeof FileExtensionCategory = FileExtensionCategory;

  public typesOptions = [
    { label: 'LIBRARY_PAGE.folders', type: FileExtensionCategory.FOLDER },
    { label: 'LIBRARY_PAGE.images', type: FileExtensionCategory.IMAGE },
    { label: 'LIBRARY_PAGE.pdfs', type: FileExtensionCategory.PDF },
    { label: 'LIBRARY_PAGE.documents', type: FileExtensionCategory.DOCUMENT },
    { label: 'LIBRARY_PAGE.spreadsheets', type: FileExtensionCategory.SPREADSHEET },
    { label: 'LIBRARY_PAGE.archives', type: FileExtensionCategory.ARCHIVE },
    { label: 'LIBRARY_PAGE.videos', type: FileExtensionCategory.VIDEO },
    { label: 'LIBRARY_PAGE.audio', type: FileExtensionCategory.AUDIO }
  ];

  public modifiedOptions = [
    { label: 'COMMON.today', params: { }, value: DateRangeFilterOption.TODAY },
    { label: 'COMMON.last_n_days', params: { count: 7 }, value: DateRangeFilterOption.LAST_7_DAYS },
    { label: 'COMMON.last_n_days', params: { count: 30 }, value: DateRangeFilterOption.LAST_30_DAYS },
    { label: 'COMMON.this_year', params: { year: new Date().getFullYear() }, value: DateRangeFilterOption.THIS_YEAR },
    { label: 'COMMON.last_year', params: { year: new Date().getFullYear() - 1 }, value: DateRangeFilterOption.LAST_YEAR },
  ];

  private destroy$: Subject<void> = new Subject();

  private _filters: LibraryFilters;

  constructor(private readonly formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.createForm();
  
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(filters => this.changed.emit(filters));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handleOpen(): void {
    if (!this.cssClass) {
      return;
    }

    setTimeout(() => {
      // Dynamic class and appendTo bug fix
      const panel = document.querySelector('.ng-dropdown-panel');
      if (panel) {
        panel.classList.add(this.cssClass);
      }
    }, 0);
  }

  private patchFormValue(): void {
    if (!this.form || !this._filters) {
      return;
    }

    this.form.patchValue(this._filters, { emitEvent: false });
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      modified: [''],
      extensionType: [''],
      tags: [[]],
      isPinned: [false]
    });

    this.patchFormValue();
  }
}
