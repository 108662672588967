import { Inject, Injectable } from '@angular/core';
import { API_CORE_APP_URL, API_URL } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class SharedLibraryService {

  public environments: any = {
    apiUrl: this.apiUrl,
    apiCoreAppUrl: this.apiCoreAppUrl
  };

  constructor(
    @Inject(API_URL) private apiUrl: string,
    @Inject(API_CORE_APP_URL) private apiCoreAppUrl: string
  ) { }

  public updateEnvironments(data: any): void {
    this.environments = { ...this.environments, ...data };
  }
}
