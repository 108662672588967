import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import ScrollView from '@app/plugins/native/scroll-view.plugin';
import { BehaviorSubject, Observable } from 'rxjs';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private contentOnlyMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private topPaddingOnPhone$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private nativeNavigationBar$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta,
  ) { }

  public get contentOnlyMode(): Observable<boolean> {
    return this.contentOnlyMode$.asObservable();
  }

  public get topPaddingOnPhone(): Observable<boolean> {
    return this.topPaddingOnPhone$.asObservable();
  }

  public get nativeNavigationBar(): Observable<boolean> {
    return this.nativeNavigationBar$.asObservable();
  }

  public get contentOnlyModeValue(): boolean {
    return this.contentOnlyMode$.value;
  }

  public get nativeNavigationBarValue(): boolean {
    return this.nativeNavigationBar$.value;
  }

  public toggleContentOnlyMode(): void {
    this.contentOnlyMode$.next(!this.contentOnlyMode$.value);
  }

  public enableContentOnlyMode(): void {
    this.contentOnlyMode$.next(true);
  }

  public disableContentOnlyMode(): void {
    this.contentOnlyMode$.next(false);
  }

  public removeTopPaddingOnPhone(): void {
    this.topPaddingOnPhone$.next(true);
  }

  public resetTopPaddingOnPhone(): void {
    this.topPaddingOnPhone$.next(false);
  }

  public enableNativeNavigationBar(): void {
    this.nativeNavigationBar$.next(true);
  }

  public removeNativeNavigationBar(): void {
    this.nativeNavigationBar$.next(false);
  }

  public async setNativeNavigationBarHeight(): Promise<void> {
    const isNative = this.isNativePlatformAndPluginAvailable();
    const shouldUseFullViewport = isNative && (await ScrollView.contentInsetStatus()).value === 'never';

    const height = shouldUseFullViewport ? '100px' : '85px';

    this.document.body.style.setProperty('--lms-native-navigation-bar-height', height);
  }

  public async setNativeViewPortMode(): Promise<void> {
    const isNative = this.isNativePlatformAndPluginAvailable();
    const shouldUseFullViewport = isNative && (await ScrollView.contentInsetStatus()).value === 'never';

    if (shouldUseFullViewport) {
      const existingMeta = this.meta.getTag('name="viewport"');
      this.meta.updateTag({ name: 'viewport', content: `${existingMeta.content}, viewport-fit=cover` });

      this.document.documentElement.style.setProperty('--safe-area-inset-top', 'env(safe-area-inset-top)');
      this.document.body.style.height = '100vh';
      return;
    }

    this.document.documentElement.style.removeProperty('--safe-area-inset-top');
    this.document.body.style.height = '100%';
  }

  private isNativePlatformAndPluginAvailable(): boolean {
    return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios' && Capacitor.isPluginAvailable('ScrollView');
  }

}
