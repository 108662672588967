import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, catchError, filter, pairwise, tap, map, debounceTime, finalize } from 'rxjs/operators';
import { EntityTypeId, StorageKeys, VideoStatisticsLog } from '../models';
import { HttpService } from './http.service';
import { STORAGE_KEYS } from '../constants';
import { ProfileService } from './profile.service';
import { StorageService } from './storage.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VideoProgressTrackerService {
  private progressStorage: { [id: string]: VideoStatisticsLog } = {};

  private storageKey: string;

  constructor(
    private readonly storageService: StorageService,
    @Inject(STORAGE_KEYS) private readonly storageKeys: StorageKeys,
    private readonly profileService: ProfileService,
    private readonly httpService: HttpService) {
      this.initialize();
    }

  private initialize(): void {
    this.profileService.getCurrentProfile()
      .pipe(
        distinctUntilChanged((old, current) => (old && current) && old.id === current.id)
      )
      .subscribe(profile => {
        this.storageKey = `${this.storageKeys.USER_VIDEO_PROGRESS}-${profile?.id}`;

        if (!profile) {
          return;
        }

        this.progressStorage = this.storageService.getItem(this.storageKey) || {};

        const keys = Object.values(this.progressStorage);
        keys.forEach(item => this.logVideoStats(item));
      });
  }

  public getVideoProgressSubject(relateEntityId: string, mediaId: string, relatedEntityTypeId = EntityTypeId.NODE): BehaviorSubject<number> {
    const data = { 
      relateEntityId, 
      mediaId, 
      relatedEntityTypeId,
      segments: []
    };

    return this.trackVideoWatchProgress(data);
  }

  public logVideoStats(data: VideoStatisticsLog): void {
    if (!data) {
      return;
    }

    delete this.progressStorage[data?.mediaId];
    this.storeCurrentStateInStorage();

    if (data.segments?.length === 0) {
      return;
    }

    this.updateVideoStatistics(data)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err?.status !== 400) {
            this.progressStorage[data?.mediaId] = data;
            this.storeCurrentStateInStorage();
          }

          return of(null);
        })
      ).subscribe();
  }


  public updateVideoStatistics(data: VideoStatisticsLog): Observable<void> {
    return this.httpService.post('video-statistics', data);
  }


  private trackVideoWatchProgress(data: VideoStatisticsLog): BehaviorSubject<number> {
    if (!data) {
      return new BehaviorSubject(0);
    }

    let timestamp: BehaviorSubject<number> = new BehaviorSubject(0);
  
    timestamp
      .pipe(
        map(value => Math.ceil(value)),
        pairwise(),
        filter(([prev, curr]) => curr - prev < 5),
        tap(([prev, curr])  => {          
          let storageData = this.progressStorage[data?.mediaId];

          if (!storageData) {
            Object.assign(this.progressStorage, { 
              [data.mediaId]: { ...data, segments: [] }
            });

            storageData = this.progressStorage[data?.mediaId];
          }

          let segments = storageData.segments.map(item => ({ start: item.st, end: item.et })) || [];
          segments = this.appendWatchedFragment(segments, { start: prev, end: curr });          
          const date = new Date().toISOString();
        
          storageData.segments = segments?.map(item => ({ st: item.start, et: item.end, ts: date })) || [];

          console.log(JSON.stringify(this.progressStorage))

          this.storeCurrentStateInStorage();
        }),
        debounceTime(3000),
        map(() => {
          this.logVideoStats(this.progressStorage[data?.mediaId]);
        }),
        finalize(() => {
          this.logVideoStats(this.progressStorage[data?.mediaId]);
        })
      ).subscribe();

    return timestamp;
  }

  private appendWatchedFragment(current: { start: number, end: number }[], fragment: { start: number, end: number }): { start: number, end: number }[] {
    current.push(fragment);
    current = current.sort((prev, curr) => prev.start - curr.start);

    const fragmentsOverlapping = (curr, prev) => {
      const epsilon = 0.5;
      return Math.max(curr.end, prev.end) - Math.min(curr.start, prev.start) < (curr.end - curr.start) + (prev.end - prev.start) + epsilon;
    };

    return current?.reduce((current: { start: number, end: number }[], item: { start: number, end: number }) => {
      if (current.length === 0) {
        return [item];
      }

      const last = current[current.length - 1];

      if (fragmentsOverlapping(item, last)) {
        Object.assign(last, { start: Math.min(item.start, last.start), end: Math.max(item.end, last.end) })
      } else {
        current.push(item);
      }

      return current;
    }, []) || [];
  }

  private storeCurrentStateInStorage(): void {
    this.storageService.setItem(this.storageKey, JSON.stringify(this.progressStorage));
  }
}
