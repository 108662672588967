<div class="event-material">
  <lib-file-icon class="file-icon" [file]="item" [size]="24"></lib-file-icon>

  <span class="file-name" ellipsis [ellipsis-content]="item.filename || item.name"></span> 

  <button mat-icon-button class="button button--icon button--icon-extra-small" (click)="downloadFile()">
    <lib-icon icon="download-simple"></lib-icon>
  </button>

  <button mat-icon-button class="button button--icon button--icon-extra-small" *ngIf="deleteEnabled" (click)="deleteFile()">
    <lib-icon icon="trash"></lib-icon>
  </button>
</div>
