export const environment = {
  production: false,
  name: 'dev',
  sentryUrl: '',
  apiUrl: 'https://internal.backend.dev.thelearning-lab.com/',
  apiCoreAppUrl: 'https://internal.core.dev.thelearning-lab.com/app/',
  zoomHostUrl: 'https://zoom-client.dev.thelearning-lab.com/',
  renderingToolUrl: 'https://renderer.dev.thelearning-lab.com/api',
  hostLookupUrl: 'https://controltower.dev.thelearning-lab.com/api/instances/resolver',
  hash: new Date().toISOString().replace(/\.|:|-/g, ''),
  buildArtifactsUrl: 'https://app.dev.thelearning-lab.com/zip/build_artifacts_',
  encrytionSecret: 'DEV_THISISTEMPRORARYANDWILLBEAUTOMATEDWITHTIMETHANKYOU#232$53209',
  adminBaseDomain: 'https://internal.admin.lms-development.thelearning-lab.com',
  frontBaseDomain: 'https://internal.app.lms-development.thelearning-lab.com',
  version: '',
};
