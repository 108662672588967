<div class="achievement-modal" data-cy="achievement-modal">
  <div class="achievement-modal__header">
    <button mat-icon-button class="button button--icon button--transparent" mat-dialog-close>
      <lib-icon icon="x"></lib-icon>
    </button>
  </div>
  <div class="achievement-modal__body">
    <div class="achievement-modal__image-wrapper">
      <img class="achievement-modal__image" 
        [placeholderImage]="!achievement.mediaImage && achievement.bundle"
        [cropImage]="achievement.mediaImage | preSignedUrl | async" 
        [additionalOptions]="{width: 240, height: 240, func: 'bound'}" 
        alt="achievement">
    </div>
    <div class="achievement-modal__body--title">{{achievement.title}}</div>
    <ng-container *ngIf="achievement.achievementType === AchievementTypeEnum.QUANTITIVE">
      <div class="achievement-modal__body--contents">
        {{achievement.numberOfCompletedContents}}/{{achievement.numberOfContents}} <lib-icon icon="stack" [size]="16"></lib-icon> {{bundleTypeTextMapPlural[achievement.contentType] | translate }}
      </div>
      <mat-progress-bar class="default-theme" mode="determinate" [value]="progress"></mat-progress-bar>
    </ng-container>

    <div *ngIf="achievement.description" class="achievement-modal__body--description">
      <p class="ck-text" [innerHtml]="achievement.description | safeHtml"></p>
    </div>
  </div>
</div>